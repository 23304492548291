<template>
  <div class="navbar-container d-flex flex-column">
    <div class="d-flex content align-items-center">
      <!-- Left Col -->
      <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
        <!-- Bookmarks Container -->
        <!-- <bookmarks /> -->
        <!-- LOGO V -->
        <b-img
          :src="require('@/assets/images/illustration/logo1.svg')"
          height="35"
          alt="Sriphat PHR"
          @click="$router.push({ name: 'member-home' })"
        />
      </div>
      <b-navbar-nav class="nav align-items-center ml-auto">
        <locale />
        <!-- <feather-icon
          class="mr-25"
          icon="MoreVerticalIcon"
        /> -->
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        <!-- <search-bar /> -->
        <!-- <cart-dropdown /> -->
        <!-- <notification-dropdown /> -->
        <user-dropdown />
      </b-navbar-nav>
    </div>
    <!-- <div>
      <profile-view-as />
    </div> -->
  </div>
</template>

<script>
import {
  BNavbarNav,
} from 'bootstrap-vue'
// import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
// import ProfileViewAs from '../../../../views/profile/ProfileViewAs.vue'

export default {
  components: {
    // BLink,

    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    // SearchBar,
    // DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    // ProfileViewAs,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
